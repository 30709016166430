// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as basicErrorController from './basicErrorController';
import * as Chapter from './Chapter';
import * as Course from './Course';
import * as fileController from './fileController';
import * as Lesson from './Lesson';
import * as Section from './Section';
import * as userController from './userController';
export default {
  basicErrorController,
  Chapter,
  Course,
  fileController,
  Lesson,
  Section,
  userController,
};
