import cn from 'classnames';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocalStorage } from 'react-use';

import ExpandIcon from '@/assets/images/profile/expanded.inline.svg';
import FoldedIcon from '@/assets/images/profile/folded.inline.svg';
import { useTranslation } from '@/i18n';
import api from '@/services/api';
import { useGuardQuery } from '@/utils/hooks';

import { EduCourseTab } from './EduCourseTab';
import { ProfileAccordion } from './ProfileAccordion';

export type ProfileContentType = 'edu_history' | 'edu_awards';
export type EduHistoryTabType = 'inprogress' | 'finished';
interface IEduTab {
  type: EduHistoryTabType;
  name: string;
}
const eduTabs: IEduTab[] = [
  {
    type: 'inprogress',
    name: 'profile.tab.in_progress'
  },
  {
    type: 'finished',
    name: 'profile.tab.finished'
  }
];

export function ProfileContent() {
  const { t } = useTranslation();
  const [showLeft, setShowLeft] = useLocalStorage('profile_show_left', false);
  const [contentType, setContentType] = useLocalStorage<ProfileContentType>(
    'profile-content-type',
    'edu_history'
  );
  const [eduHistoryTab, setEduHistoryTab] = useLocalStorage<EduHistoryTabType>(
    'profile-edu-history-tab',
    'inprogress'
  );

  const { data: courseData } = useQuery(['lessons'], () => {
    if (contentType === 'edu_history') {
      return api.Course.queryListUsingGET();
    }
  });
  const courseIds = useMemo(() => {
    return courseData?.data?.map((item) => item.id as number) || [];
  }, [courseData?.data]);
  const { data: progressData } = useGuardQuery([courseIds], () => {
    if (courseIds.length) {
      return api.Course.getProgressesUsingPOST({ courseIds });
    }
  });

  return (
    <section className="page-layout !min-h-auto relative flex flex-row gap-x-[30px] !overflow-x-hidden">
      <aside
        className={cn(
          'overflow-hidden rounded bg-[#16202D] px-[11px] py-[14px] duration-200',
          showLeft
            ? 'fixed bottom-0 left-0 top-16 z-10 w-[220px] sm:relative sm:bottom-auto sm:left-auto sm:top-auto sm:h-[782px]'
            : 'absolute left-6 h-10 w-10 shrink-0 sm:relative sm:left-0'
        )}
      >
        <div className="cursor-pointer">
          {showLeft ? (
            <ExpandIcon onClick={() => setShowLeft(false)} />
          ) : (
            <FoldedIcon onClick={() => setShowLeft(true)} />
          )}
        </div>
        <ProfileAccordion
          setContentType={setContentType}
          contentType={contentType}
        />
      </aside>
      <main className="w-full flex-1 sm:h-[782px]">
        <ul className="ml-14 flex w-full items-center gap-x-4 border-b-[2px] border-solid border-[#242E3D] pb-2 sm:ml-2 sm:gap-x-10">
          {eduTabs.map((item) => (
            <li
              key={item.name}
              className={cn(
                'relative cursor-pointer font-medium leading-6 duration-200',
                eduHistoryTab === item.type
                  ? 'text-[#256BEF]'
                  : 'text-[#CDD5E0]'
              )}
              onClick={() => {
                setEduHistoryTab(item.type);
              }}
            >
              <span>{t(item.name)}</span>
              <div
                className={cn(
                  'absolute -bottom-[10px] left-0 h-[2px] bg-[#256BEF] duration-200',
                  eduHistoryTab === item.type ? 'w-full' : 'w-0'
                )}
              />
            </li>
          ))}
        </ul>
        {contentType === 'edu_history' && (
          <EduCourseTab
            courseData={courseData?.data}
            progressData={progressData?.data}
            type={eduHistoryTab}
          />
        )}
      </main>
    </section>
  );
}
