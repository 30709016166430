// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Query Section By ID GET /edu/v1/sections/${param0} */
export async function getSectionUsingGET1(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSectionUsingGET1Params,
  options?: { [key: string]: any },
) {
  const { sectionId: param0, ...queryParams } = params;
  return request<API.ResponseSectionDTO>(`/edu/v1/sections/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
