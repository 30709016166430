import cn from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useClickAway, useToggle } from 'react-use';

import ArrowLeft from '@/assets/images/common/arrow-left.inline.svg';
import BackCourses from '@/assets/images/courses/back-courses.inline.svg';
import BackHome from '@/assets/images/courses/back-home.inline.svg';
import CompletedIcon from '@/assets/images/courses/completed.inline.svg';
import CourseLogo from '@/assets/images/courses/course-logo.inline.svg';
import CourseLogoAttach from '@/assets/images/courses/course-logo-attach.inline.svg';
import LockIcon from '@/assets/images/courses/lock.inline.svg';
import { CircleProgress } from '@/components';
import { useTranslation } from '@/i18n';
import api from '@/services/api';
import {
  COURSE_DETAIL_PATH,
  DEFAULT_SEQUENCE,
  ESearchParamKeys,
  isMoveCastle
} from '@/utils/const';

import { DaySteps } from './DaySteps';

interface CourseDayDetailLayoutProps {
  className?: string;
  title: ReactNode;
  children: ReactNode;
  sectionSequence?: number;
  chapterSequence?: number;
  lessonSequence?: number;
  courseId: string;
  sections: API.SectionDTO[];
  courseProgress?: API.CourseProgressDTO;
}

export type LessonStatus = 'locked' | 'unlocked' | 'completed';

export function CourseDayDetailLayout(props: CourseDayDetailLayoutProps) {
  const {
    className,
    children,
    title,
    sectionSequence,
    lessonSequence,
    courseId,
    sections,
    courseProgress,
    chapterSequence
  } = props;
  const [showLessonList, toggleShowLessonList] = useToggle(false);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(sectionSequence);
  useEffect(() => {
    if (sectionSequence !== undefined) setCurrentStep(sectionSequence);
  }, [sectionSequence]);
  const sectionId = useMemo(
    () => sections.find((item) => item.sequence === currentStep)?.id,
    [currentStep, sections]
  );
  const { data: sectionData } = useQuery(
    ['api.Section.getSectionUsingGET1', sectionId],
    () => {
      if (sectionId) {
        return api.Section.getSectionUsingGET1({
          sectionId
        });
      }
    }
  );

  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(ref, () => {
    if (showLessonList) {
      toggleShowLessonList(false);
    }
  });
  const [showBackMenus, toggleShowBackMenus] = useToggle(false);
  const backMenusRef = useRef<HTMLUListElement | null>(null);
  useClickAway(backMenusRef, () => {
    toggleShowBackMenus(false);
  });
  const [hoverLessonSequence, setHoverLessonSequence] = useState<number>();

  return (
    <div className={cn(className, 'relative flex h-screen w-screen flex-col')}>
      {!isMoveCastle && (
        <ul
          ref={backMenusRef}
          className={cn(
            'fixed left-0 top-[76px] z-[100] w-[130px] cursor-pointer overflow-hidden bg-[#16202D] text-base font-medium text-white shadow duration-200',
            showBackMenus ? 'h-24 opacity-100' : 'h-0 opacity-0'
          )}
        >
          <Link to={COURSE_DETAIL_PATH}>
            <li className="flex h-12 items-center gap-x-2 px-4 text-white hover:bg-primaryColor">
              <BackCourses />
              <span>{t('lesson.back_button.courses')}</span>
            </li>
          </Link>
          <Link to="/">
            <li className="line-clamp-1 flex h-12 items-center gap-x-2 px-4 text-white hover:bg-primaryColor">
              <BackHome />
              <span>{t('lesson.back_button.home')}</span>
            </li>
          </Link>
        </ul>
      )}
      <header className="fixed top-0 z-10 h-[72px] w-full flex-[0_0_72px] bg-[#131C28]">
        <div className="flex">
          <div
            className={cn(
              'relative flex h-[72px] w-16 shrink-0 items-center justify-center bg-[#2F74FA] sm:w-20',
              !isMoveCastle && 'cursor-pointer'
            )}
            onClick={toggleShowBackMenus}
          >
            <CourseLogo />
            <CourseLogoAttach className="absolute bottom-[18px] right-4" />
          </div>
          <Link
            to={`${COURSE_DETAIL_PATH}/${courseId}`}
            className="ml-4 flex flex-row items-center gap-x-1 text-white duration-200 hover:text-primaryColor sm:ml-8 sm:gap-x-2"
          >
            <ArrowLeft className="shrink-0" />
            <h2 className="ml-2 line-clamp-1 text-xl font-semibold leading-[72px]">
              {title}
            </h2>
          </Link>
        </div>
      </header>
      <section className="flex flex-1">
        <aside ref={ref}>
          <div className="sticky left-0 top-[72px] h-[calc(100vh_-_72px)] w-16 overflow-auto bg-[#131C28] sm:w-20">
            <DaySteps
              totalSteps={sections.length}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              showLessonList={showLessonList}
              toggleShowLessonList={toggleShowLessonList}
              courseProgress={courseProgress}
              chapterSequence={chapterSequence}
            />
          </div>
          <div
            className={cn(
              'fixed left-16 top-0 z-10 h-screen overflow-hidden border-r-[1px] border-solid border-[#697D9B33] bg-[#131C28] duration-300 sm:left-20',
              showLessonList ? 'w-[250px] sm:w-[400px]' : 'w-0'
            )}
          >
            <div className="flex h-[72px] items-center justify-end bg-[#131C28] pr-5">
              <ArrowLeft
                className="cursor-pointer"
                onClick={toggleShowLessonList}
              />
            </div>
            <ul
              onMouseLeave={() => {
                setHoverLessonSequence(undefined);
              }}
              className="flex flex-col gap-y-1"
            >
              {sectionData?.data?.lessons?.map((lesson) => {
                const lessonStatus = getLessonStatus(
                  courseProgress as any,
                  lesson
                );
                const isHoverOnLesson = lesson.sequence === hoverLessonSequence;
                const isLessonActive =
                  sectionData?.data?.sequence === sectionSequence &&
                  lesson.sequence === lessonSequence;
                return (
                  <li
                    onMouseEnter={() => {
                      setHoverLessonSequence(lesson.sequence);
                    }}
                    key={lesson.id}
                    className={cn(
                      'relative flex items-center justify-between overflow-hidden px-[10px] text-xs leading-[1] sm:text-sm',
                      isLessonActive
                        ? 'h-12 bg-primaryColor text-[#CDD5E0]'
                        : 'h-11 bg-[#16202D] text-white',
                      lessonStatus !== 'locked' &&
                        !isLessonActive &&
                        'cursor-pointer'
                    )}
                    onClick={() => {
                      if (lessonStatus === 'locked') return;
                      toggleShowLessonList();
                      void navigate(
                        getLessonLink(
                          lesson.sectionSequence as number,
                          lesson.sequence as number
                        )
                      );
                    }}
                  >
                    {isLessonActive && (
                      <div className="absolute left-[1px] top-[50%] h-8 w-[3px] -translate-y-[50%] rounded-[0px_4px_4px_0px] bg-white"></div>
                    )}
                    <span
                      className={cn(
                        'line-clamp-1 flex-1 font-medium',
                        !isLessonActive &&
                          lessonStatus !== 'locked' &&
                          isHoverOnLesson &&
                          'text-primaryColor',
                        lessonStatus === 'locked' && 'text-[#97A2B8]'
                      )}
                    >
                      {lesson.sequence}. {lesson.title}
                    </span>
                    {lessonStatus === 'locked' && (
                      <LockIcon
                        width={28}
                        height={28}
                        className="shrink-0 text-[#97A2B8]"
                      />
                    )}
                    {lessonStatus === 'unlocked' && (
                      <CircleProgress
                        size="28"
                        progress={0}
                        progressColor={
                          isLessonActive
                            ? '#fff'
                            : isHoverOnLesson
                            ? '#3E81FF'
                            : '#fff'
                        }
                        textColor={
                          isLessonActive
                            ? '#fff'
                            : isHoverOnLesson
                            ? '#3E81FF'
                            : '#fff'
                        }
                        bgColor={
                          isLessonActive
                            ? '#ffffff'
                            : isHoverOnLesson
                            ? '#3E81FF33'
                            : '#ffffff'
                        }
                        textStyle={{ font: '9rem Poppins' }}
                      />
                    )}
                    {lessonStatus === 'completed' && (
                      <CompletedIcon
                        width={28}
                        height={28}
                        className={cn(
                          'shrink-0',
                          'text-white',
                          isHoverOnLesson &&
                            !isLessonActive &&
                            ' !text-[#3E81FF]'
                        )}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </aside>

        <main className="h-full flex-1 overflow-auto overflow-y-auto overflow-x-hidden bg-[#0a111a] pt-[72px]">
          {children}
        </main>
      </section>
    </div>
  );
}

function getLessonLink(sectionSequence: number, lessonSequence: number) {
  const url = new URL(window.location.href);
  url.searchParams.set(
    ESearchParamKeys.LESSON_SEQUENCE,
    lessonSequence.toString()
  );
  url.searchParams.set(
    ESearchParamKeys.SECTION_SEQUENCE,
    sectionSequence.toString()
  );
  return `${url.pathname}${url.search}`;
}

export function getLessonStatus(
  courseProgress?: API.CourseProgressDTO,
  lesson?: API.LessonDTO
) {
  if (courseProgress === undefined || lesson === undefined) return 'locked';
  const hasStarted = !!courseProgress;
  const hasFinished = !!courseProgress?.completed;
  let lessonStatus: LessonStatus = 'locked';
  if (hasStarted) {
    if (hasFinished) {
      lessonStatus = 'completed';
    } else {
      // 开始，但是没有结束，那么判断sectionSequence和lessonSequence
      const currentLessonSequence = lesson.sequence as number;
      const currentSectionSequence = lesson.sectionSequence as number;
      const currentChapterSequence = lesson.chapterSequence as number;
      const progressLessonSequence = courseProgress?.lessonSequence as number;
      const progressSectionSequence = courseProgress?.sectionSequence as number;
      const progressChapterSequence = courseProgress?.chapterSequence as number;
      if (currentChapterSequence < progressChapterSequence) {
        lessonStatus = 'completed';
      } else if (currentChapterSequence === progressChapterSequence) {
        if (currentSectionSequence < progressSectionSequence) {
          lessonStatus = 'completed';
        } else if (currentSectionSequence === progressSectionSequence) {
          if (currentLessonSequence < progressLessonSequence) {
            lessonStatus = 'completed';
          } else if (currentLessonSequence === progressLessonSequence) {
            lessonStatus = 'unlocked';
          }
        }
      }
    }
  } else {
    if (
      lesson.chapterSequence === DEFAULT_SEQUENCE &&
      lesson.sectionSequence === DEFAULT_SEQUENCE &&
      lesson.sequence === DEFAULT_SEQUENCE
    ) {
      // 没有开始，那么只有第一个chapter第一个section第一个lesson是解锁的
      lessonStatus = 'unlocked';
    }
  }
  return lessonStatus;
}
