import { navigate } from 'gatsby';

import { ESearchParamKeys, isMoveCastle } from './const';

export async function waitTime(time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, time);
  });
}

export function getSearchParamsFromUrl(keys: string[], url?: string) {
  const newUrl = url || location.href;
  const search = new URL(newUrl).searchParams;
  const result = keys.map((item) => search.get(item) || '');
  return result;
}

export function removeSearchParamsShallow(keys: string[], url?: string) {
  const newUrl = url || location.href;
  const search = new URL(newUrl).searchParams;
  for (const key of keys) {
    search.delete(key);
  }
  const { hash, origin, pathname } = location;
  const hasSearchKey = !![...search.keys()].length;
  const newSearchStr = hasSearchKey ? `?${search.toString()}` : '';
  history.replaceState(null, '', `${origin}${pathname}${newSearchStr}${hash}`);
}

export function removeSearchParamOnPath(key: string, url?: string) {
  const newUrl = url || location.href;
  const urlObj = new URL(newUrl);
  const searchObj = urlObj.searchParams;
  if (searchObj.has(key)) {
    searchObj.delete(key);
    history.replaceState(
      {},
      '',
      `${urlObj.pathname}${
        searchObj.toString() ? '?' + searchObj.toString() : ''
      }`
    );
  }
}
// 这是一个再次修改后的算法，用户名保留前后两位字符
export function abbreviateEmail(email: string): string {
  const abbreviation = email.slice(0, 3) + '...' + email.slice(-3);
  // 返回缩写后的邮箱
  return abbreviation;
}

export function isOdd(num: number): boolean {
  return num % 2 === 1;
}

// 定义一个函数，接受一个html字符串作为参数
export function openLinksInNewTab(html: string): string {
  // 定义一个正则表达式，匹配a标签的href属性
  const regex = /<a href=".*?"/g;
  // 使用replace方法，将匹配到的字符串后面添加target="_blank"
  return html.replace(regex, '$& target="_blank"');
}

export function checkIsInLessonDetailPage() {
  const { search } = location;
  const result =
    search.includes(`?${ESearchParamKeys.SECTION_SEQUENCE}=`) ||
    search.includes(`?${ESearchParamKeys.LESSON_SEQUENCE}=`);
  console.log(result);
  return result;
}

export function login() {
  const pathWithSearch = `${location.pathname}${location.search}`;
  void navigate(`/authCallbackCenter?redirect_uri=${pathWithSearch}`);
}

export function getCourseIdFromPath() {
  const courseId = location.pathname.split('/')[2];
  if (!isMoveCastle) return Number(courseId);
  if (courseId === 'move-on-sui') {
    return 105;
  } else {
    return Number(courseId);
  }
}

export function getPathFromCourseId(id?: number) {
  if (!isMoveCastle) return id?.toString() || '';
  if (id === undefined) return '';
  if (id === 105) return 'move-on-sui';
  return id.toString();
}
