/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { CardLayout } from '@/components';
import { startLearningEmitter } from '@/components/common/IFrameEditor';
import { useTranslation } from '@/i18n';
import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { getPathFromCourseId, login } from '@/utils/common';
import {
  COURSE_DETAIL_PATH,
  DEFAULT_SEQUENCE,
  ESearchParamKeys,
  EStorageKeys
} from '@/utils/const';
import { useScrollRestore } from '@/utils/hooks';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  SyllabusAccordion
} from './SyllabusAccordion';
import { SyllabusProgressIcon } from './SyllabusProgressIcon';

interface ISectionSyllabusProps {
  className?: string;
  courseDetail?: API.CourseDTO;
  courseProgress?: API.CourseProgressDTO;
}
export type SectionStatus = 'locked' | 'unlocked' | 'completed';
export function SectionSyllabus(props: ISectionSyllabusProps) {
  const { className, courseDetail, courseProgress } = props;
  const { t } = useTranslation();
  const { userInfo } = useAppStore();
  const [hoverChapterSequence, setHoverChapterSequence] = useState<number>();
  const [hoverSectionSequence, setHoverSectionSequence] = useState<number>();
  const hasStarted = useMemo(() => !!courseProgress, [courseProgress]);
  const hasFinished = useMemo(
    () => courseProgress?.completed,
    [courseProgress?.completed]
  );
  useScrollRestore(EStorageKeys.COURSE_DETAIL_PAGE_SCROLL_POSITION, 300);
  return (
    <section className={cn(className)}>
      <CardLayout title={t('course.syllabus.title')}>
        <div className="flex flex-col gap-y-[6px]">
          {courseDetail?.chapters?.map((chapter) => {
            if (
              chapter.id === undefined ||
              chapter.name === undefined ||
              chapter.sections === undefined ||
              chapter.sequence === undefined
            )
              return null;
            const chapterEstimateTime = chapter.sections.reduce((acc, cur) => {
              if (cur.estimatedMinutes) {
                return acc + Number(cur.estimatedMinutes);
              }
              return acc;
            }, 0);
            return (
              <SyllabusAccordion
                storageKey={`syllabus_storage_course_${
                  courseDetail.id || ''
                }_chapter_${chapter.sequence}`}
                key={chapter.id}
                type="single"
                collapsible={true}
              >
                <AccordionItem value={chapter.sequence.toString()}>
                  <AccordionTrigger>
                    <div className="flex flex-1 flex-col sm:flex-row">
                      <span className="flex flex-1 text-left text-base">
                        {`Chapter ${chapter.sequence}: ${chapter.name}`}
                      </span>
                      <span className="text-left text-base font-normal text-[#97A2B8]">
                        {chapter.sections?.length} {t('common.lessons')} |{' '}
                        {chapterEstimateTime} {t('common.min')}
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    {chapter.sections.map((section, index) => {
                      const isLast =
                        index ===
                        (chapter.sections as API.SectionDTO[]).length - 1;
                      const isHoverOnSection =
                        hoverChapterSequence === chapter.sequence &&
                        hoverSectionSequence === section.sequence;
                      let sectionStatus: SectionStatus = 'locked';
                      let sectionProgress = 0;
                      if (hasStarted) {
                        if (hasFinished) {
                          sectionStatus = 'completed';
                          sectionProgress = 100;
                        } else {
                          // 已经开始，根据sequence计算当前section的状态和进度
                          const currentChapterSequence =
                            chapter.sequence as number;
                          const currentSectionSequence =
                            section.sequence as number;
                          const progressChapterSequence =
                            courseProgress?.chapterSequence as number;
                          const progressSectionSequence =
                            courseProgress?.sectionSequence as number;
                          const progressLessonSequence =
                            courseProgress?.lessonSequence as number;
                          if (
                            currentChapterSequence < progressChapterSequence
                          ) {
                            // 之前的chapter都是完成的
                            sectionStatus = 'completed';
                            sectionProgress = 100;
                          } else if (
                            currentChapterSequence === progressChapterSequence
                          ) {
                            if (
                              currentSectionSequence < progressSectionSequence
                            ) {
                              // 之前的section都是完成的
                              sectionStatus = 'completed';
                              sectionProgress = 100;
                            } else if (
                              currentSectionSequence === progressSectionSequence
                            ) {
                              // 当前section是正在进行的
                              sectionStatus = 'unlocked';
                              // 当前 section的进度是依据 progressLessonSequence / section.lessons.length来计算的
                              if (
                                section.lessonCount &&
                                progressLessonSequence
                              ) {
                                sectionProgress = Math.floor(
                                  ((progressLessonSequence - 1) /
                                    section.lessonCount) *
                                    100
                                );
                              }
                            }
                          }
                        }
                      } else {
                        // 没有开始，那么只有第一chapter的第一个section是解锁的
                        if (chapter.sequence === 1 && section.sequence === 1) {
                          sectionStatus = 'unlocked';
                        }
                      }
                      return (
                        <div
                          key={section.sequence}
                          onClick={async () => {
                            if (sectionStatus === 'locked') return;
                            let lessonSequence = DEFAULT_SEQUENCE;
                            if (
                              !userInfo &&
                              sectionStatus === 'unlocked' &&
                              section.sequence === 1
                            ) {
                              login();
                              return;
                            }
                            if (
                              !hasStarted &&
                              chapter.sequence === 1 &&
                              section.sequence === 1 &&
                              courseDetail.id !== undefined
                            ) {
                              // 如果没有开始，那么点击第一个chapter的第一个section，应该先做start learning
                              try {
                                await api.Course.startCourseUsingPOST({
                                  courseId: courseDetail.id
                                });
                                startLearningEmitter.fire();
                              } catch (e) {
                                console.error(e);
                                toast.error(t('tip.error.start_learning'));
                                return;
                              }
                            }
                            if (
                              sectionStatus === 'unlocked' &&
                              courseProgress?.lessonSequence
                            ) {
                              lessonSequence = courseProgress.lessonSequence;
                            }
                            void navigate(
                              `${COURSE_DETAIL_PATH}/${getPathFromCourseId(
                                courseDetail.id
                              )}/${chapter.sequence || ''}?${
                                ESearchParamKeys.SECTION_SEQUENCE
                              }=${section.sequence || ''}&${
                                ESearchParamKeys.LESSON_SEQUENCE
                              }=${lessonSequence}`
                            );
                          }}
                          onMouseEnter={() => {
                            setHoverChapterSequence(chapter.sequence);
                            setHoverSectionSequence(section.sequence);
                          }}
                          onMouseLeave={() => {
                            setHoverChapterSequence(undefined);
                            setHoverSectionSequence(undefined);
                          }}
                          className={cn(
                            'chapters-center my-[6px] flex cursor-pointer items-center rounded px-4 py-4 sm:pl-12 sm:pr-12',
                            'bg-[#1B2B49] text-[#97A2B8] duration-200',
                            isLast && '!mb-0',
                            isHoverOnSection &&
                              sectionStatus !== 'locked' &&
                              'bg-primaryColor text-white',
                            sectionStatus !== 'locked' && 'text-white'
                          )}
                        >
                          <SyllabusProgressIcon
                            sectionStatus={sectionStatus}
                            sectionProgress={sectionProgress}
                            isHoverOnSection={isHoverOnSection}
                          />
                          <div className="chapters-center flex flex-1 font-['Poppins'] text-base leading-[1.5]">
                            <span
                              className={cn(
                                'flex-1',
                                section.sequence === 1 && 'pl-[2px]'
                              )}
                            >
                              {section.sequence}. {section.name}
                            </span>
                            <span>
                              {section.estimatedMinutes || 0} {t('common.min')}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </AccordionContent>
                </AccordionItem>
              </SyllabusAccordion>
            );
          })}
        </div>
      </CardLayout>
    </section>
  );
}
