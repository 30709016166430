import cn from 'classnames';
import { m } from 'framer-motion';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import ArrowLink from '@/assets/images/common/arrow-link-2.inline.svg';
import starTrackPoster from '@/assets/images/home/star-track-poster.png';
import starTrackVideo from '@/assets/videos/star-track.mp4';
import { PrimaryButton } from '@/components/common/PrimaryButton';
import { useTranslation } from '@/i18n';

interface SectionBannerProps {
  className?: string;
}

export function SectionBanner(props: SectionBannerProps) {
  const { className } = props;

  const { t } = useTranslation();

  return (
    <section className={cn('md:-mt-16', className)}>
      <div className="relative">
        <div className="relative h-screen md:h-auto md:min-h-screen">
          <m.video
            initial={{ opacity: 0, transform: 'translate3d(0, -20px, 0)' }}
            whileInView={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
            transition={{
              type: 'spring',
              duration: 0.6
            }}
            loop={true}
            autoPlay={true}
            muted={true}
            controls={false}
            src={starTrackVideo}
            poster={starTrackPoster}
            className="-z-10 hidden min-h-screen w-screen object-cover pt-16 md:block"
          />
          <StaticImage
            src="../../../../assets/images/home/home-banner-mobile.png"
            alt="Home Banner"
            layout="fullWidth"
            objectFit="contain"
            className="-z-10 block md:hidden"
          />
          <div className="absolute inset-x-0 top-[12%] z-10 mx-auto flex max-w-5xl flex-col items-center text-center md:top-[15%]">
            <m.h1
              initial={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
              animate={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
              transition={{
                type: 'spring',
                delay: 0.1,
                duration: 0.8
              }}
              className="max-w-[260px] font-Manrope text-2xl font-bold leading-9 text-[#FAFAFF] md:max-w-none md:px-[5vw] md:text-[64px] md:leading-[96px]"
            >
              {t('home.section.one.title')}
            </m.h1>

            <m.p
              initial={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
              animate={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
              transition={{
                type: 'spring',
                delay: 0.2,
                duration: 0.8
              }}
              className="my-8 max-w-xs text-sm font-medium text-[#9BBDEB] md:my-6 md:max-w-none md:px-[3vw] md:text-xl md:leading-10"
            >
              {t('home.section.one.desc')}
            </m.p>

            <m.div
              initial={{ opacity: 0, transform: 'translate3d(0, -10px, 0)' }}
              animate={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
              transition={{
                type: 'spring',
                delay: 0.3,
                duration: 0.8
              }}
              className="flex flex-wrap justify-center gap-4 3xl:mt-8"
            >
              <PrimaryButton
                onClick={() => navigate('/courses')}
                className="px-3 py-1 md:px-5"
              >
                <span className="text-xs leading-5 md:text-xl md:leading-10">
                  {t('home.section.one.buttonText')}
                </span>
                <ArrowLink className="mt-1 w-6" />
              </PrimaryButton>
            </m.div>
          </div>
        </div>
      </div>
    </section>
  );
}
