// @ts-ignore
/* eslint-disable */
import request from '../request';

/** errorHtml GET /edu/error */
export async function errorHtmlUsingGET(options?: { [key: string]: any }) {
  return request<API.ModelAndView>('/edu/error', {
    method: 'GET',
    ...(options || {}),
  });
}

/** errorHtml PUT /edu/error */
export async function errorHtmlUsingPUT(options?: { [key: string]: any }) {
  return request<API.ModelAndView>('/edu/error', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** errorHtml POST /edu/error */
export async function errorHtmlUsingPOST(options?: { [key: string]: any }) {
  return request<API.ModelAndView>('/edu/error', {
    method: 'POST',
    ...(options || {}),
  });
}

/** errorHtml DELETE /edu/error */
export async function errorHtmlUsingDELETE(options?: { [key: string]: any }) {
  return request<API.ModelAndView>('/edu/error', {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** errorHtml PATCH /edu/error */
export async function errorHtmlUsingPATCH(options?: { [key: string]: any }) {
  return request<API.ModelAndView>('/edu/error', {
    method: 'PATCH',
    ...(options || {}),
  });
}
