/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from 'lodash';
import { useMemo } from 'react';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';

import { useAppStore } from '@/store/appStore';

export function useGuardQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<TData, TError> {
  const { userInfo } = useAppStore();
  const newQueryKey = useMemo(() => {
    if (isArray(queryKey)) {
      return [...queryKey, userInfo];
    } else {
      return [queryKey, userInfo];
    }
  }, [queryKey, userInfo]);
  return useQuery(newQueryKey as any, () => {
    if (!userInfo) return undefined;
    return queryFn({
      queryKey: newQueryKey as unknown as any,
      ...(options as any)
    });
  });
}
