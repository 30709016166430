// @ts-ignore
/* eslint-disable */
import request from '../request';

/** User Get Profile GET /edu/v1/user */
export async function getUserInfoUsingGET(options?: { [key: string]: any }) {
  return request<API.ResponseUserInfoDTO>('/edu/v1/user', {
    method: 'GET',
    ...(options || {}),
  });
}
