import cn from 'classnames';
import { m } from 'framer-motion';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';

import stageGif1 from '@/assets/images/home/stages/stage-image-1.gif';
// import stageGif2 from '@/assets/images/home/stages/stage-image-2.gif';
import stageGif3 from '@/assets/images/home/stages/stage-image-3.gif';
import { PrimaryButton } from '@/components/common/PrimaryButton';
import { useTranslation } from '@/i18n';
import { StaticQueryChildImageSharp } from '@/types/gatsby-image';

interface SectionStagesProps {
  className?: string;
}

const courseDemoLinks = ['/demo/video-based', '/demo/interactive-learning'];

export function SectionStages(props: SectionStagesProps) {
  const { className } = props;

  const { t } = useTranslation();

  const { starBackground } = useStaticQuery<{
    starBackground: StaticQueryChildImageSharp;
  }>(
    graphql`
      query {
        starBackground: file(relativePath: { eq: "home/bg-stars-1.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2880) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );
  const imageData = starBackground.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      className={cn(className)}
      style={{ backgroundPositionY: '18%', zIndex: 10 }}
    >
      <div className="mx-auto max-w-screen-xl px-6 py-8 lg:px-8 2xl:max-w-screen-2xl">
        <div className="py-4 text-center">
          <h2 className="font-Manrope text-xl font-bold leading-[30px] sm:text-[56px] sm:leading-[80px]">
            {t('home.section.stages')}
          </h2>
        </div>
        <section className="mt-4 grid grid-cols-1 gap-12 md:mt-8 lg:mt-16 lg:grid-cols-2 lg:gap-24 3xl:gap-48">
          <m.div
            initial={{ opacity: 0, translateX: '-10%' }}
            whileInView={{ opacity: 1, translateX: '0)' }}
            transition={{
              type: 'spring',
              duration: 0.8
            }}
            className="relative flex h-64 items-center overflow-hidden rounded-lg text-center sm:h-80 lg:h-full lg:text-right"
          >
            <img src={stageGif1} alt="Split-Screen Teaching" />
          </m.div>

          <m.div
            initial={{ opacity: 0, translateX: '10%' }}
            whileInView={{ opacity: 1, translateX: '0)' }}
            transition={{
              type: 'spring',
              duration: 0.8
            }}
            className="flex flex-col items-start lg:py-24"
          >
            <div className="font-Manrope text-sm capitalize text-[#69758D] 3xl:text-lg">
              {t('home.section.stage1.label')}
            </div>
            <h2 className="my-[14px] text-xl font-bold capitalize md:text-[32px] md:leading-[48px] 3xl:text-4xl">
              {t('home.section.stage1.title')}
            </h2>

            <p className="text-left text-base text-[#97A2B8] md:leading-[30px] 3xl:text-2xl">
              {t('home.section.stage1.desc')}
            </p>

            <div className="mt-8 flex items-center gap-[18px]">
              <PrimaryButton
                onClick={() => navigate(courseDemoLinks[0])}
                className="px-6 py-1 text-sm font-medium capitalize leading-9 md:px-12 md:py-[10px] md:text-xl"
              >
                {t('home.section.stage.buttonText')}
              </PrimaryButton>
              <Link
                to="/courses"
                className="inline-block text-sm font-medium capitalize leading-9 text-[#50F5FF] underline underline-offset-2 hover:text-[#50F5FF]/80 focus:outline-none md:text-xl"
              >
                {t('home.section.stage.linkText')}
              </Link>
            </div>
          </m.div>
        </section>
        {/* <section className="mt-16 grid grid-cols-1 gap-8 md:mt-12 lg:mt-12 lg:grid-cols-2 lg:gap-24 3xl:gap-48">
          <m.div
            initial={{ opacity: 0, translateX: '10%' }}
            whileInView={{ opacity: 1, translateX: '0)' }}
            transition={{
              type: 'spring',
              duration: 0.8
            }}
            className="relative flex h-64 items-center overflow-hidden rounded-lg text-center sm:h-80 lg:order-last lg:h-full lg:text-left"
          >
            <img src={stageGif2} alt="Video-based Teaching" />
          </m.div>

          <m.div
            initial={{ opacity: 0, translateX: '-10%' }}
            whileInView={{ opacity: 1, translateX: '0)' }}
            transition={{
              type: 'spring',
              duration: 0.8
            }}
            className="flex flex-col items-start lg:items-end lg:py-24"
          >
            <div className="font-Manrope text-sm capitalize text-[#69758D] 3xl:text-lg">
              {t('home.section.stage2.label')}
            </div>
            <h2 className="my-[14px] text-xl font-bold capitalize md:text-[32px] md:leading-[48px] 3xl:text-4xl">
              {t('home.section.stage2.title')}
            </h2>

            <p className="text-left text-base text-[#97A2B8] md:leading-[30px] 3xl:text-2xl">
              {t('home.section.stage2.desc')}
            </p>

            <div className="mt-8 flex items-center gap-[18px] lg:flex-row-reverse">
              <PrimaryButton
                onClick={() => navigate(courseDemoLinks[0])}
                className="py-1 px-6 text-sm font-medium capitalize leading-9 md:py-[10px] md:px-12 md:text-xl"
              >
                {t('home.section.stage.buttonText')}
              </PrimaryButton>
              <a
                href="#"
                className="inline-block text-sm font-medium capitalize leading-9 text-[#50F5FF] underline underline-offset-2 hover:text-[#50F5FF]/80 focus:outline-none md:text-xl"
              >
                {t('home.section.stage.linkText')}
              </a>
            </div>
          </m.div>
        </section> */}
        <section className="mt-8 grid grid-cols-1 gap-4 md:mt-16 lg:mt-12 lg:grid-cols-2 lg:gap-24 3xl:gap-48">
          <m.div
            initial={{ opacity: 0, translateX: '10%' }}
            whileInView={{ opacity: 1, translateX: '0)' }}
            transition={{
              type: 'spring',
              duration: 0.8
            }}
            className="relative flex h-64 items-center overflow-hidden rounded-lg text-center sm:h-80 lg:order-last lg:h-full lg:text-left"
          >
            <img src={stageGif3} alt="Interactive learning environment" />
          </m.div>

          <m.div
            initial={{ opacity: 0, translateX: '-10%' }}
            whileInView={{ opacity: 1, translateX: '0)' }}
            transition={{
              type: 'spring',
              duration: 0.8
            }}
            className="flex flex-col items-start lg:items-end lg:py-24"
          >
            <div className="font-Manrope text-sm capitalize text-[#69758D] 3xl:text-lg">
              {t('home.section.stage3.label')}
            </div>
            <h2 className="my-[14px] text-xl font-bold capitalize md:text-[32px] md:leading-[48px] 3xl:text-4xl">
              {t('home.section.stage3.title')}
            </h2>

            <p className="text-left text-base text-[#97A2B8] md:leading-[30px] lg:text-right 3xl:text-2xl">
              {t('home.section.stage3.desc')}
            </p>

            <div className="mt-8 flex items-center gap-[18px] lg:flex-row-reverse">
              <PrimaryButton
                onClick={() => navigate(courseDemoLinks[1])}
                className="px-6 py-1 text-sm font-medium capitalize leading-9 md:px-12 md:py-[10px] md:text-xl"
              >
                {t('home.section.stage.buttonText')}
              </PrimaryButton>
              <Link
                to="/courses"
                className="inline-block text-sm font-medium capitalize leading-9 text-[#50F5FF] underline underline-offset-2 hover:text-[#50F5FF]/80 focus:outline-none md:text-xl"
              >
                {t('home.section.stage.linkText')}
              </Link>
            </div>
          </m.div>
        </section>
      </div>
    </BackgroundImage>
  );
}
