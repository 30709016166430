/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import React, { useMemo } from 'react';

import PassIcon from '@/assets/images/courses/pass.inline.svg';
import { AttributeTag, getTags } from '@/components';
import { useTranslation } from '@/i18n';

import { CourseButton } from './CourseButton';
import { ProgressSlider } from './ProgressSlider';

interface ISectionCourseHeaderProps {
  className?: string;
  courseDetail?: API.CourseDTO;
  courseProgress?: API.CourseProgressDTO;
}

export function SectionCourseHeader(props: ISectionCourseHeaderProps) {
  const { className, courseDetail, courseProgress } = props;
  const { t } = useTranslation();
  const progressNumber = useMemo(() => {
    if (courseProgress?.completed) return 100;
    if (
      courseDetail?.totalLessons !== undefined &&
      courseProgress?.learntLessons !== undefined
    ) {
      return Math.floor(
        (courseProgress.learntLessons / courseDetail.totalLessons) * 100
      );
    }
    return 0;
  }, [
    courseDetail?.totalLessons,
    courseProgress?.completed,
    courseProgress?.learntLessons
  ]);

  const currentChapter = useMemo(() => {
    return courseDetail?.chapters?.find(
      (item) => item.id === courseProgress?.chapterId
    );
  }, [courseDetail?.chapters, courseProgress?.chapterId]);

  const hasStarted = useMemo(() => !!courseProgress, [courseProgress]);
  const hasFinished = useMemo(
    () => courseProgress?.completed || progressNumber === 100,
    [courseProgress?.completed, progressNumber]
  );

  return (
    <section className={cn(className, 'relative')}>
      <h1 className="my-10 text-[40px] font-bold leading-[1.2] text-white">
        {courseDetail?.name}
      </h1>
      <p className="text-[18px] leading-[1.5] text-[#CDD5E0]">
        {courseDetail?.description}
      </p>
      <div className="mb-[11px] mt-5 flex flex-row flex-wrap items-center gap-x-[10px] gap-y-2 text-[18px] text-[#CDD5E0]">
        <span>{t('course.property.attr')}</span>
        {getTags(courseDetail).map((item, index) => (
          <AttributeTag key={index} {...item} />
        ))}
      </div>
      <div className="flex flex-row flex-wrap items-center gap-x-4 gap-y-2 text-[18px] text-[#CDD5E0]">
        <span>{t('course.property.skills')}</span>
        {courseDetail?.skills?.map((item, index) => {
          const isLast = index === (courseDetail.skills || []).length - 1;
          return (
            <>
              <span className="text-[#50F5FF]">{item}</span>
              {!isLast && <span className="text-[#50F5FF]">/</span>}
            </>
          );
        })}
      </div>
      <div
        className={cn(
          'mt-[10px] flex flex-row flex-wrap items-center gap-x-4 gap-y-4 text-[18px] text-[#CDD5E0]',
          hasStarted ? 'mb-[63px]' : 'mb-6'
        )}
      >
        <span>{t('course.property.progress')}</span>
        <div className="relative flex-1">
          <div className="flex flex-row gap-x-2">
            <ProgressSlider
              value={[progressNumber]}
              className="ml-1"
              disabled={true}
            />
            &nbsp;&nbsp;
            <span className="text-base text-white">{progressNumber}%</span>
          </div>

          {hasStarted && (
            <div className="absolute left-1 w-full text-[#50F5FF]">
              {`Chapter ${currentChapter?.sequence || 1} : ${
                currentChapter?.name || ''
              }`}
            </div>
          )}
        </div>
      </div>
      {hasFinished && (
        <PassIcon className="mb-8 md:absolute md:bottom-11 md:right-0 md:mb-0" />
      )}
      <CourseButton
        courseId={courseDetail?.id}
        courseProgress={courseProgress}
      />
    </section>
  );
}
